import { Instance, getLanguageItemByLocale } from "@/lib/helpers/directus";
import { readItems } from '@directus/sdk'

export const getSpecialsPathnames = async () => {

    const deepFilterObject = {
        special_tours: {
            status: {
                _eq: 'published',
            }
        },
    };

    const filterObject = {};

    const fields = [
        '*',
        {
            translations: [
                '*',
                'languages_code.slug_code',
            ],
            special_tours: [
                '*',
                {
                    translations: [
                        '*',
                        'languages_code.slug_code',
                    ]
                }
            ],
        }
    ];

    const specials_page_data = await Instance.request(readItems('specials_page', {
        deep: deepFilterObject,
        filter: filterObject,
        fields: fields
    }));

    if (!specials_page_data) {
        return null;
    } else {
        if (specials_page_data.special_tours) {
            const special_tours = specials_page_data.special_tours;

            return special_tours;
        } else {
            return null;
        }
    }
}

export const getSpecials = async (locale) => {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
        };

        const filterObject = {};

        const fields = [
            '*',
            {
                translations: [
                    '*',
                    'languages_code.slug_code',
                ],
                special_tours: [
                    '*',
                    {
                        translations: [
                            '*',
                            'languages_code.slug_code',
                        ]
                    }
                ],
            }
        ];

        const specials_page_data = await Instance.request(readItems('specials_page', {
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!specials_page_data) {
            return null;
        } else {
            if (specials_page_data.special_tours) {
                const special_tours = specials_page_data.special_tours;

                return special_tours.map((special_tour) => {
                    if (special_tour) {
                        special_tour.translated_content = special_tour.translations[0];
                        delete special_tour.translations;
                    }

                    return special_tour;
                });
            } else {
                return null;
            }
        }
    }
}