export const route = (params, href, suffix = '') => {
    if (typeof window !== 'undefined' && process.env.NEXT_APP_ENVIRONMENT !== 'local') {
        console.error = function () { }
    }

    if (!params) {
        // console.error('route() params is required');
        return href;
    }
    if (!params.routeHelperData) {
        // console.error('route() params.routeHelperData is required');
        return href;
    }
    if (!params.locale) {
        // console.error('route() params.locale is required');
        return href;
    }

    const routeHelperData = params.routeHelperData;
    const locale = params.locale;
    
    if (!routeHelperData.pathnamesWithInfo) {
        // console.error('route() params.routeHelperData.pathnamesWithInfo is required');
        return href;
    }
    if (!routeHelperData.languages) {
        // console.error('route() params.routeHelperData.languages is required');
        return href;
    }
    if (!routeHelperData.settings) {
        // console.error('route() params.routeHelperData.settings is required');
        return href;
    }

    const pathnamesWithInfo = routeHelperData.pathnamesWithInfo;
    const languages = routeHelperData.languages;
    const settings = routeHelperData.settings;

    const locales = languages.map((language) => language.slug_code);
    const defaultLocale = settings.default_locale.slug_code;

    const currentLocale = locale ?? defaultLocale;
    let pathname = href ?? null;

    if(pathname === null) {
        return href;
    }

    let regex = new RegExp(`/${currentLocale}`, 'g');
    for (const locale of locales) {
        regex = new RegExp(`/${locale}`, 'g');
        pathname = pathname.replace(regex, '');
    }

    pathname = pathname.replace(/\/$/, '');
    if (pathname === '') {
        pathname = '/';
    }

    const pathnamesWithInfoArray = Object.values(pathnamesWithInfo);
    // console.log('pathnamesWithInfoArray', pathnamesWithInfoArray);
    let foundPath = pathnamesWithInfoArray.find((path) => {
        return path.pathnames[defaultLocale] === pathname;
    });

    if (!foundPath) {
        console.error(`route(${href}) path not found default (${defaultLocale})`);

        foundPath = pathnamesWithInfoArray.find((path) => {
            return path.pathnames[currentLocale] === pathname;
        });

        if (!foundPath) {
            console.error(`route(${href}) path not found in ${currentLocale}`);
            return href;
        } else {
            if(href.includes('terms')) {
                // console.log('foundPath with currentLocale', foundPath);
            }
        }
    } else {
        if(href.includes('terms')) {
            // console.log('foundPath with defaultLocale', foundPath);
        }
    }

    // return foundPath.pathnames[defaultLocale] + suffix;

    // console.log('locale and defaultLocale', locale, defaultLocale, foundPath ?? 'Not found');

    if (locale == defaultLocale) {
        return `${foundPath.pathnames[defaultLocale]}${suffix ?? ''}`;
    } else {
        return `/${locale}${foundPath.pathnames[locale]}${suffix ?? ''}`;
    }
}