import { Instance } from "@/lib/helpers/directus";
import { createItem, readItems } from '@directus/sdk';
import { t } from '@/lib/helpers/directus';

export const getForms = async (params) => {
    const language = params.langByLocale ?? 'en';
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
            groups: {
                translations: deepLangFilterObj,
                status: {
                    _eq: 'published',
                },
                form_groups_id: {
                    translations: deepLangFilterObj,
                    status: {
                        _eq: 'published',
                    },
                    fields: {
                        fields_id: {
                            translations: deepLangFilterObj,
                            status: {
                                _eq: 'published',
                            }
                        }
                    }
                }
            }
        };

        const filterObject = {
            status: {
                _eq: 'published',
            }
        };

        const fields = [
            '*',
            {
                translations: [
                    '*'
                ],
                groups: [
                    '*',
                    {
                        form_groups_id: [
                            '*',
                            {
                                translations: [
                                    '*'
                                ],
                                fields: [
                                    '*',
                                    {
                                        fields_id: [
                                            '*',
                                            {
                                                translations: [
                                                    '*'
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ];

        const forms = await Instance.request(readItems('forms', {
            sort: ['sort', 'date_created'],
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!forms) {
            return null;
        } else {
            return forms.map((form) => {
                if (form) {
                    form.translated_content = form.translations[0];
                    delete form.translations;
                }

                form.groups.map((group) => {
                    group.union_id = group.id;
                    delete group.id;
                    group.data = group.form_groups_id;
                    delete group.form_groups_id;

                    if (group.data.translations) {
                        group.data.translated_content = group.data.translations[0];
                        delete group.data.translations;
                    }

                    group.data.fields.map((field) => {
                        field.union_id = field.id;
                        delete field.id;
                        field.data = field.fields_id;
                        delete field.fields_id;
    
                        if (field.data.translations) {
                            field.data.translated_content = field.data.translations[0];
                            delete field.data.translations;
                        }
                    });
                });

                return form;
            });
        }
    }
};
export const createEntry = async (formId, data, collection, itemId, params, token) => {
    return new Promise(async (resolve, reject) => {
        const googleResponse = await fetch('/api/recaptcha', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: token
            })
        });

        const googleData = await googleResponse.json();
        if (!googleData.success) {
            reject(t(params, 'form_error'));
            return;
        }

        let collectionArray = null;
        if (collection && itemId) {
            collectionArray = [
                {
                    item: itemId + '',
                    collection: collection
                }
            ];
        }

        const response = await Instance.request(createItem('form_entries', {
            form_id: formId,
            data: data,
            collection: collectionArray
        }));

        if (response) {
            resolve(t(params, 'form_success'));
        } else {
            reject(t(params, 'form_error'));
        }
    });
};