import { Instance, getTourCategoryData, getTourData, getCruiseData, getBoatData, getManauseInfoData, getManausAmazonData, getManausActivityData, getTourCategories, MenuGeneration } from "@/lib/helpers/directus";
import { readItems, readCollections, readCollection, readFile } from '@directus/sdk';

// import settings from '@/lib/cacheLayer/settings.json';

// import Settings from '../../cacheLayer/settings.json';

// export async function getSettings(locale = '', source = '') {
//     return Settings.settings;

//     // let settings = null;
//     // try {
//     //     const response = await fetch(`${process.env.NEXT_APP_URL}/api/settings`, {
//     //         method: 'POST',
//     //         headers: {
//     //             'Content-Type': 'application/json',
//     //         },
//     //         body: JSON.stringify({ locale: locale, source: source}),
//     //     });
        
//     //     const data = await response.json();
//     //     if (!data.settings) {
//     //         console.error('Error: No settings found');
//     //         return null;
//     //     };
//     //     settings = data.settings;
//     // } catch (error) {
//     //     console.error('Error:', error);
//     //     return null;
//     // }

//     // return settings;
// }

export async function getSettings(locale = '') {
    const settings = await Instance.request(readItems('settings', {
        single: true,
        fields: [
            'whatsapp_phone',
            'whatsapp_phone_link',
            'email',
            'email_link',
            // 'default_locale.id',
            'default_locale.code',
            'default_locale.slug_code',
            'logo_dark',
            'logo_light',
            'logo_no_text',
            'show_icons_in_top_menu',
            'translations.languages_code.slug_code',
            'translations.languages_code.code',
            'translations.settings_id.translations.*',
            {
                favicon: [
                    'id',
                    'folder',
                    'type',
                    'title',
                    'filename_disk',
                    'filename_download',
                    'width',
                    'height',
                    'duration',
                ],
            }
        ],
    }));

    if (locale) {
        const settingsTranslation = settings.translations.find((translation) => translation.languages_code.slug_code === locale);
        if (settingsTranslation) {
            const langCode = settingsTranslation.languages_code.code;
            const correctTranslation = settingsTranslation.settings_id.translations.find((translation) => translation.languages_code === langCode);
            
            if(correctTranslation) {
                settings.translated_content = correctTranslation;

                delete settings.translations;
            }
        }
    }

    settings.languages = await getLanguages();

    return settings;
}

export async function getRelationalNavigationItems(locale, collection_name) {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
        };

        const filterObject = {
            status: {
                _eq: 'published',
            }
        };

        const fields = [
            'id',
            'slug',
            {
                translations: [
                    'title',
                ],
            }
        ];

        try {
            const items = await Instance.request(readItems(collection_name, {
                sort: ['sort', 'date_created'],
                deep: deepFilterObject,
                filter: filterObject,
                fields: fields
            }));
    
            if (!items) {
                return null;
            } else {
                return items.map((item) => {
                    if (item) {
                        item.translated_content = item.translations[0];
                        delete item.translations;
                    }

                    return item;
                });
            }
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    }
}

export async function getCollectionItemData(locale, collection_name, id) {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const itemData = await Instance.request(readItems(collection_name, {
            deep: {
                translations: deepLangFilterObj,
            },
            filter: {
                id: {
                    _eq: id,
                }
            },
            fields: [
                '*',
                {
                    translations: [
                        '*'
                    ]
                }
            ],
            limit: 1,
        })) ?? null;

        if (itemData) {
            if (itemData.translations) {
                itemData.translated_content = itemData.translations[0];
                delete itemData.translations;
            }
            
            return itemData;
        } else {
            return null;
        }
    }
}

export async function getNavigations(locale, params) {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        let returnObject = {};
        const footerData = await Instance.request(readItems('footer', {
            deep: {
                translations: {
                    _filter: {
                        _and: [
                            {
                                languages_code: { _eq: language.code },
                            },
                        ],
                    },
                },
            },
            fields: [
                '*',
                {
                    translations: [
                        '*'
                    ]
                }
            ],
            limit: 1,
        })) ?? null;

        if (footerData) {
            footerData.translated_content = footerData.translations[0];
            delete footerData.translations;

            if (footerData.translated_content && footerData.translated_content.bottombar) {
                for await (const item of footerData.translated_content.bottombar) {
                    if (item.is_relational_list && item.collection_name) {

                        const subItems = await getRelationalNavigationItems(locale, item.collection_name);
                        if (subItems) {
                            item.list = Array.from(subItems.map((subItem) => {
                                if(!subItem) {
                                    return null;
                                }

                                const link = subItem.slug ? subItem.slug : null;
                                let title = null;
                                if (subItem.translated_content) {
                                    title = subItem.translated_content.title;
                                }

                                return {
                                    label: title,
                                    icon_name: null,
                                    url: link,
                                }
                            }));
                        }
                    }
                }
            }
            
            returnObject.footer = footerData;
        } else {
            returnObject.footer = null;
        }

        const headerData = await Instance.request(readItems('header', {
            deep: {
                translations: {
                    _filter: {
                        _and: [
                            {
                                languages_code: { _eq: language.code },
                            },
                        ],
                    },
                },
            },
            fields: [
                '*',
                {
                    translations: [
                        '*'
                    ]
                }
            ],
            limit: 1,
        })) ?? null;

        if (headerData) {
            headerData.translated_content = headerData.translations[0];
            delete headerData.translations;

            const menuGeneration = new MenuGeneration();
            if (headerData.translated_content && headerData.translated_content.topbar) {
                for await (const item of headerData.translated_content.topbar) {
                    if (item.use_generation_function && item.function_name) {
                        // const subItems = await menuGeneration[item.function_name]();
                        const subItems = await menuGeneration[`menu_${item.function_name}`](locale, params) ?? null;
                        if (subItems) {
                            item.list = subItems.map((subItem, index) => {
                                return {
                                    ...subItem,
                                    index: index,
                                }
                            });
                        }
                    }
                }
            }
            
            returnObject.header = headerData;
        } else {
            returnObject.header = null;
        }

        return returnObject;
    }
}

export async function getLanguages() {
    return Instance.request(readItems('languages', {
        single: true,
        filter: {
            status: {
                _eq: 'published',
            }
        },
        fields: [
            // 'id',
            'code',
            'slug_code',
            'icon_code',
            'name',
        ],
    }));
}

export async function getMessages(params) {
    if(!params || !params.locale || !params.langByLocale) {
        return {};
    }

    const deepLangFilterObj = {
        _filter: {
            _and: [
                {
                    languages_code: { _eq: params.langByLocale.code },
                },
            ],
        },
    };

    const translations = await Instance.request(readItems('translation_strings', {
        deep: {
            translations: deepLangFilterObj,
        },
        filter: {
            status: {
                _eq: 'published',
            }
        },
        fields: [
            '*',
            {
                translations: [
                    '*'
                ]
            }
        ],
        limit: -1,
    }));

    const messages = {};
    for await (const translation of translations) {
        if (translation.translations.length === 0) {
            continue;
        }

        
        let value = translation.translations[0].value;
        value = value.replace(/<[^>]*>/g, (match) => {
            if (match.startsWith('<a') || match.startsWith('</a') ||
                match.startsWith('<span') || match.startsWith('</span') ||
                match.startsWith('<strong') || match.startsWith('</strong')
            ) {
                return match;
            } else {
                return '';
            }
        });
        
        messages[translation.translation_key] = value;

    }

    return messages;
}

export function t(params, translation_key) {
    if (typeof params === 'string') {
        params = { locale: params };
    }

    if (!params.messages) {
        return translation_key;
    }


    const value = params.messages[translation_key] ?? null;
    if (value) {
        return value;
    } else {
        console.error('Missing translation:', translation_key);
        return translation_key;
    }
}

export async function getLanguageItemByLocale(locale) {
    const settings = await getSettings('', 'getLanguageItemByLocale');
    const languages = settings.languages;
    const language = languages.find((language) => language && language.slug_code === locale);

    if (language) {
        return language;
    }
}

export async function getAllCollections() {
    return await Instance.request(readCollections());
}
export async function getCollectionByName(name) {
    return await Instance.request(readCollection(name));
}

export async function getStaticPages() {
    const collections = await Instance.request(readCollections());
    const pageCollections = collections.filter((collection) => {
        if (collection.meta.collection_type === 'static_page' && collection.meta.singleton) {
            return collection;
        } else {
            return null;
        }
    });

    const staticPages = [];

    for await (const collection of pageCollections) {
        const item = await Instance.request(readItems(collection.collection, {
            fields: [
                '*',
                'translations.*',
                'translations.*.slug_code',
            ],
            limit: -1
        }));

        item.collection = collection.collection;

        staticPages.push(item);
    }

    return staticPages;
}

export async function getStaticPageData(locale, page) {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const pageData = await Instance.request(readItems(page, {
            deep: {
                translations: deepLangFilterObj,
                seo: {
                    status: {
                        _eq: 'published',
                    },
                    translations: deepLangFilterObj,
                },
                cards: {
                    translations: deepLangFilterObj,
                },
                information_blocks: {
                    translations: deepLangFilterObj,
                    status: {
                        _eq: 'published',
                    }
                },
                content_blocks: {
                    status: {
                        _eq: 'published',
                    },
                    translations: deepLangFilterObj,
                },
                special_tours: {
                    status: {
                        _eq: 'published',
                    },
                    translations: deepLangFilterObj,
                },
                boats: {
                    status: {
                        _eq: 'published',
                    },
                    translations: deepLangFilterObj,
                },
            },
            fields: [
                '*',
                {
                    seo: [
                        '*',
                        {
                            translations: [
                                '*',
                            ]
                        }
                    ],
                },
                {
                    header_media: [
                        'id',
                        'folder',
                        'type',
                        'title',
                        'filename_disk',
                        'filename_download',
                        'width',
                        'height',
                        'duration',
                    ],
                },
                {
                    translations: [
                        '*',
                        // '*.our_details',
                        // '*.other_pages',
                        // '*.license_tourist_agency',
                        // '*.faq_items',
                        // '*.fundamentals',
                        // '*.text_blocks',
                    ]
                },
                {
                    cards: [
                        '*',
                        {
                            header_media: [
                                'id',
                                'folder',
                                'type',
                                'title',
                                'filename_disk',
                                'filename_download',
                                'width',
                                'height',
                                'duration',
                            ],
                            translations: [
                                '*'
                            ]
                        }
                    ],
                    information_blocks: [
                        '*',
                        {
                            media: [
                                'id',
                                'folder',
                                'type',
                                'title',
                                'filename_disk',
                                'filename_download',
                                'width',
                                'height',
                                'duration',
                            ],
                            translations: [
                                '*'
                            ]
                        }
                    ],
                    content_blocks: [
                        '*',
                        {
                            media: [
                                'id',
                                'folder',
                                'type',
                                'title',
                                'filename_disk',
                                'filename_download',
                                'width',
                                'height',
                                'duration',
                            ],
                            translations: [
                                '*',
                            ],
                        },
                    ],
                    special_tours: [
                        '*',
                        {
                            header_media: [
                                'id',
                                'folder',
                                'type',
                                'title',
                                'filename_disk',
                                'filename_download',
                                'width',
                                'height',
                                'duration',
                            ],
                            translations: [
                                '*',
                            ]
                        }
                    ],
                    boats: [
                        '*',
                        {
                            header_media: [
                                'id',
                                'folder',
                                'type',
                                'title',
                                'filename_disk',
                                'filename_download',
                                'width',
                                'height',
                                'duration',
                            ],
                            translations: [
                                '*',
                            ]
                        }
                    ],
                },
            ],
            limit: 1,
        })) ?? null;

        if (pageData) {
            pageData.collection = page;
            
            pageData.translated_content = pageData.translations[0];
            delete pageData.translations;

            if (pageData.cards) {
                pageData.cards.forEach((card) => {
                    card.translated_content = card.translations[0];
                    // delete card.translations;
                });
            }

            if (pageData.information_blocks) {
                pageData.information_blocks.forEach((information_block) => {
                    information_block.translated_content = information_block.translations[0];
                    delete information_block.translations;
                });
            }

            if (pageData.content_blocks) {
                pageData.content_blocks.map((content_block) => {
                    content_block.translated_content = content_block.translations[0];
                    delete content_block.translations;
                });
            }

            if (pageData.special_tours) {
                pageData.special_tours.forEach((special_tour) => {
                    special_tour.translated_content = special_tour.translations[0];
                    delete special_tour.translations;
                });
            }

            if (pageData.seo) {
                pageData.seo.translated_content = pageData.seo.translations[0];
                delete pageData.seo.translations;
            }

            if (pageData.boats) {
                pageData.boats.forEach((boat) => {
                    boat.translated_content = boat.translations[0];
                    delete boat.translations;
                });
            }

            return pageData;
        } else {
            return null;
        }
    }
}

export async function getDynamicPageData(locale, collection_name, id) {
    let dynamicPageData = null;
    let originalCollectionName = null;
    switch (collection_name) {
        case 'tours_category_page':
            dynamicPageData = await getTourCategoryData(locale, id);
            originalCollectionName = 'tours_categories';
            break;
        case 'tour_page':
            dynamicPageData = await getTourData(locale, id);
            originalCollectionName = 'tours';
            break;
        case 'special_tour_page':
            dynamicPageData = await getTourData(locale, id);
            originalCollectionName = 'special_tours';
            break;
        case 'cruise_page':
            dynamicPageData = await getCruiseData(locale, id);
            originalCollectionName = 'cruises';
            break;
        case 'boat_page':
            dynamicPageData = await getBoatData(locale, id);
            originalCollectionName = 'boats';
            break;
        
        case 'manausamazon_info_items':
            dynamicPageData = await getManauseInfoData(locale, id);
            originalCollectionName = 'manausamazon_info_items';
            break;
        case 'manausamazon_amazon_items':
            dynamicPageData = await getManausAmazonData(locale, id);
            originalCollectionName = 'manausamazon_amazon_items';
            break;
        case 'manausamazon_activities':
            dynamicPageData = await getManausActivityData(locale, id);
            originalCollectionName = 'manausamazon_activities';
            break;
        default:
            break;
    }

    dynamicPageData.collection = originalCollectionName;

    return dynamicPageData;
}

export const getFileUrl = (fileID) => {
    if (typeof fileID === 'object') {
        fileID = fileID.id;
    }

    return `${process.env.NEXT_APP_DIRECTUS_URL}/assets/${fileID}`;
}

export const getFileInfo = async (fileID) => {
    try {
        return await Instance.request(
            readFile(fileID, {
                fields: ['*'],
            })
        );
    } catch (error) {
        console.error('Error getting file info', error);
        return null;
    }
}