import { Instance, getLanguageItemByLocale } from "@/lib/helpers/directus";
import { readItems } from '@directus/sdk'

export const getCruises = async (locale) => {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
        };

        const filterObject = {
            status: {
                _eq: 'published',
            },
        };

        const fields = [
            '*',
            {
                header_media: [
                    'id',
                    'folder',
                    'type',
                    'title',
                    'filename_disk',
                    'filename_download',
                    'width',
                    'height',
                    'duration',
                ],
                translations: [
                    '*',
                ],
            },
        ];

        const cruises = await Instance.request(readItems('cruises', {
            sort: ['sort', 'date_created'],
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!cruises) {
            return null;
        } else {
            return cruises.map((cruise) => {
                if (cruise) {
                    cruise.translated_content = cruise.translations[0];
                    delete cruise.translations;
                }

                return cruise;
            });
        }
    }
}
export const getBoats = async (locale) => {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
        };

        const filterObject = {
            status: {
                _eq: 'published',
            },
        };

        const fields = [
            '*',
            {
                header_media: [
                    'id',
                    'folder',
                    'type',
                    'title',
                    'filename_disk',
                    'filename_download',
                    'width',
                    'height',
                    'duration',
                ],
                translations: [
                    '*',
                ],
            },
        ];

        const boats = await Instance.request(readItems('boats', {
            sort: ['sort', 'date_created'],
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!boats) {
            return null;
        } else {
            return boats.map((boat) => {
                if (boat) {
                    boat.translated_content = boat.translations[0];
                    delete boat.translations;
                }

                return boat;
            });
        }
    }
}

export const getRelatedCruises = async (locale, cruise_id) => {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
        };

        const filterObject = {
            status: {
                _eq: 'published',
            },
            id: {
                _neq: cruise_id,
            }
        };

        const fields = [
            '*',
            {
                header_media: [
                    'id',
                    'folder',
                    'type',
                    'title',
                    'filename_disk',
                    'filename_download',
                    'width',
                    'height',
                    'duration',
                ],
                translations: [
                    '*',
                ],
            },
        ];

        const cruises = await Instance.request(readItems('cruises', {
            sort: ['sort', 'date_created'],
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!cruises) {
            return null;
        } else {
            return cruises.map((cruise) => {
                if (cruise) {
                    cruise.translated_content = cruise.translations[0];
                    delete cruise.translations;
                }

                return cruise;
            });
        }
    }
}

export const getCruisesPathnames = async () => {
    const filterObject = {
        status: {
            _eq: 'published',
        }
    };

    const fields = [
        '*',
        {
            translations: [
                '*',
                'languages_code.slug_code',
            ],
        }
    ];

    const cruises = await Instance.request(readItems('cruises', {
        sort: ['sort', 'date_created'],
        // deep: deepFilterObject,
        filter: filterObject,
        fields: fields
    }));

    if (!cruises) {
        return null;
    } else {
        return cruises
    }
}

export const getCruiseData = async (locale, cruise_id) => {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
            cruise_parts: {
                status: {
                    _eq: 'published',
                },
                translations: deepLangFilterObj,
            },
            seo: {
                translations: deepLangFilterObj,
            }
        };

        const filterObject = {
            status: {
                _eq: 'published',
            },
            id: {
                _eq: cruise_id,
            }
        };

        const fields = [
            '*',
            {
                seo: [
                    '*',
                    {
                        translations: [
                            '*',
                            
                        ]
                    },
                ],
                header_media: [
                    'id',
                    'folder',
                    'type',
                    'title',
                    'filename_disk',
                    'filename_download',
                    'width',
                    'height',
                    'duration',
                ],
                translations: [
                    '*',
                    {
                        image_block_media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                    }
                ],
                cruise_parts: [
                    '*',
                    {
                        media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                    },
                    {
                        translations: [
                            '*',
                            
                        ]
                    },
                ],
            },
        ];

        const cruises = await Instance.request(readItems('cruises', {
            sort: ['sort', 'date_created'],
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!cruises) {
            return null;
        } else {
            const cruise = cruises[0];

            if (cruise) {
                if (cruise.translations) {
                    cruise.translated_content = cruise.translations[0];
                    delete cruise.translations;
                }

                if (cruise.seo) {
                    cruise.seo.translated_content = cruise.seo.translations[0];
                    delete cruise.seo.translations;
                }

                if(cruise.cruise_parts) {
                    cruise.cruise_parts.map((cruise_part) => {
                        if (cruise_part.translations) {
                            cruise_part.translated_content = cruise_part.translations[0];
                            delete cruise_part.translations;
                        }
                    });
                }

                return cruise;
            } else {
                return null;
            }
        }
    }
}

export const getBoatPathnames = async () => {
    const filterObject = {
        status: {
            _eq: 'published',
        }
    };

    const fields = [
        '*',
        {
            translations: [
                '*',
                'languages_code.slug_code',
            ],
        }
    ];

    const boats = await Instance.request(readItems('boats', {
        sort: ['sort', 'date_created'],
        // deep: deepFilterObject,
        filter: filterObject,
        fields: fields
    }));

    if (!boats) {
        console.log('getBoatPathnames boats', boats);
        return null;
    } else {
        return boats
    }
}

export const getBoatData = async (locale, boat_id) => {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
            content_blocks: {
                status: {
                    _eq: 'published',
                },
                translations: deepLangFilterObj,
            },
            seo: {
                translations: deepLangFilterObj,
            }
        };

        const filterObject = {
            status: {
                _eq: 'published',
            },
            id: {
                _eq: boat_id,
            }
        };

        const fields = [
            '*',
            {
                seo: [
                    '*',
                    {
                        translations: [
                            '*',
                            
                        ]
                    },
                ],
                header_media: [
                    'id',
                    'folder',
                    'type',
                    'title',
                    'filename_disk',
                    'filename_download',
                    'width',
                    'height',
                    'duration',
                ],
                translations: [
                    '*',
                ],
                content_blocks: [
                    '*',
                    {
                        media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                        translations: [
                            '*',
                        ],
                    },
                ],
            },
        ];

        const boats = await Instance.request(readItems('boats', {
            sort: ['sort', 'date_created'],
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!boats) {
            return null;
        } else {
            const boat = boats[0];

            if (boat) {
                boat.translated_content = boat.translations[0];
                delete boat.translations;

                if (boat.seo) {
                    boat.seo.translated_content = boat.seo.translations[0];
                    delete boat.seo.translations;
                }

                if (boat.content_blocks) {
                    boat.content_blocks.map((content_block) => {
                        content_block.translated_content = content_block.translations[0];
                        delete content_block.translations;

                        return content_block;
                    });
                }

                return boat;
            } else {
                return null;
            }
        }
    }
}