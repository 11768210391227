import React from 'react';

// forward ref
const ButtonArrowRight = React.forwardRef(({ fill = 'white', stroke = 'white', className = '' }, ref) => {
    return (
        <svg ref={ref} className={`${className} -ml-[1px]`} width="18" height="30" viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2_13)">
                <path d="M-2.345 1.2871L6.3308 1.2871L14.8271 15.1435L6.3308 29L-2.345 29" fill={`var(--color-${fill})`} />
                <path d="M-2.345 1.2871L6.3308 1.2871L14.8271 15.1435L6.3308 29L-2.345 29" stroke={`var(--color-${stroke})`} strokeWidth='2' />
            </g>
            <defs>
                <clipPath id="clip0_2_13">
                    <rect width="18" height="30" fill="white" transform="translate(18 30) rotate(-180)"/>
                </clipPath>
            </defs>
        </svg>
    );
});
ButtonArrowRight.displayName = 'ButtonArrowRight';

export default ButtonArrowRight