'use client';

import React from 'react';

import { route } from '@/lib/helpers/RouteHelper';
import "/node_modules/flag-icons/css/flag-icons.min.css";

export default function LangSwitcher({ params, languages, headerVersion = 1 }) {
	const handleLangChange = async (e) => {
		const paramsClone = JSON.parse(JSON.stringify(params))
		paramsClone.locale = e.target.value
		const newRoute = route(paramsClone, '/')
		window.location = newRoute
	}
	
	const activeLang = languages.find((lang) => lang.slug_code === params.locale)

	return (
        <>
			{headerVersion === 1 ? (
				<div className='flex w-fit gap-x-1'>
					<select defaultValue={params.locale} className='py-2 text-secondary-100 font-bold bg-transparent outline-none' onChange={handleLangChange}>
						{languages.map((language, index) => (
							<option key={index} className='text-primary-800' value={language.slug_code}>{language.name}</option>
						))}
					</select>
				</div>
			) : (
				<div className='flex w-fit gap-x-1 items-center'>
					<div className={`h-4 aspect-video bg-transparent fi fi-${activeLang.icon_code}`}>
						
					</div>
					<select defaultValue={params.locale} className='py-2 text-white font-bold font-overpass bg-transparent text-shadow-sm shadow-black outline-none' onChange={handleLangChange}>
						{languages.map((language, index) => (
							<option key={index} className='text-primary-800' value={language.slug_code}>
								{language.name}
							</option>
						))}
					</select>
				</div>
			)}
		</>
	)
}
