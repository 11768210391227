import { getTourCategories, getSpecials, getCruises, getBoats, getManausActivities, getManausInfoItems, getManausAmazonItems } from "@/lib/helpers/directus";

export class MenuGeneration {
    menu_generateToursSubMenu = async (locale) => {

        const tours_categories = await getTourCategories(locale, true);
        return tours_categories.map((category) => {
            let toursList = [];
            if (category.tours && category.tours.length > 0) {
                toursList = category.tours.map((tour) => {                    
                    return {
                        label: tour.translated_content.title,
                        href: tour.slug,
                        icon: tour.icon,
                    }
                });
            }

            return {
                label: category.translated_content.title,
                href: category.slug,
                icon: category.icon,
                list: toursList
            }
        });
    }

    menu_generateSpecialsSubMenu = async (locale, params) => {
        const specials = await getSpecials(locale);
        
        const specialsList = specials.map((special) => {
            return {
                label: special.translated_content.title,
                href: special.slug,
                icon: special.icon,
                list: []
            }
        });

        const items = [];
        if (params && params.routeHelperData && params.routeHelperData.pathnamesWithInfo) {
            const specialsPagePathname = Object.values(params.routeHelperData.pathnamesWithInfo).find((pathName) => pathName.collection === 'specials_page');
            if (specialsPagePathname) {
                items.push({
                    label: specialsPagePathname.translated_content.title,
                    href: specialsPagePathname.slug,
                    icon: specialsPagePathname.icon,
                    list: specialsList
                });
            } else {
                specialsList.forEach((special) => {
                    items.push(special);
                });
            }
        } else {
            specialsList.forEach((special) => {
                items.push(special);
            });
        }

        return items;
    }

    menu_generateCruisesSubMenu = async (locale, params) => {
        const cruises = await getCruises(locale);
        const cruisesList = cruises.map((cruise) => {
            return {
                label: cruise.translated_content.title,
                href: cruise.slug,
                icon: cruise.icon,
                list: []
            }
        });

        const boats = await getBoats(locale);
        const boatsList = boats.map((boat) => {
            return {
                label: boat.translated_content.title,
                href: boat.slug,
                icon: boat.icon,
                list: []
            }
        });

        const items = [];
        if (params && params.routeHelperData && params.routeHelperData.pathnamesWithInfo) {
            const pathnamesWithInfoArray = Object.values(params.routeHelperData.pathnamesWithInfo);
            const cruisesPagePathname = pathnamesWithInfoArray.find((pathName) => pathName.collection === 'cruises_page');
            const boatsPagePathname = pathnamesWithInfoArray.find((pathName) => pathName.collection === 'boats_page');

            if (cruisesPagePathname) {
                items.push({
                    label: cruisesPagePathname.translated_content.title,
                    href: cruisesPagePathname.slug,
                    icon: cruisesPagePathname.icon,
                    list: cruisesList
                });
            } else {
                cruisesList.forEach((cruise) => {
                    items.push(cruise);
                });
            }

            if (boatsPagePathname) {
                items.push({
                    label: boatsPagePathname.translated_content.title,
                    href: boatsPagePathname.slug,
                    icon: boatsPagePathname.icon,
                    list: boatsList
                });
            } else {
                boatsList.forEach((boat) => {
                    items.push(boat);
                });
            }
        } else {
            cruisesList.forEach((cruise) => {
                items.push(cruise);
            });

            boatsList.forEach((boat) => {
                items.push(boat);
            });
        }

        return items;
    }

    menu_generateManausAmazonSubMenu = async (locale, params) => {
        const manausActivities = await getManausActivities(locale);
        const manausActivitiesList = manausActivities.map((activity) => {
            return {
                label: activity.translated_content.title,
                href: activity.slug,
                icon: activity.icon,
                list: []
            }
        });

        const manausInfoItems = await getManausInfoItems(locale);
        const manausInfoItemsList = manausInfoItems.map((infoItem) => {
            return {
                label: infoItem.translated_content.title,
                href: infoItem.slug,
                icon: infoItem.icon,
                list: []
            }
        });

        const manausAmazonItems = await getManausAmazonItems(locale);
        const manausAmazonItemsList = manausAmazonItems.map((amazonItem) => {
            return {
                label: amazonItem.translated_content.title,
                href: amazonItem.slug,
                icon: amazonItem.icon,
                list: []
            }
        });

        const items = [];
        if (params && params.routeHelperData && params.routeHelperData.pathnamesWithInfo) {
            const pathnamesWithInfoArray = Object.values(params.routeHelperData.pathnamesWithInfo);
            
            const manausActivitiesPagePathname = pathnamesWithInfoArray.find((pathName) => pathName && pathName.collection === 'manausamazon_activities_page');
            if (manausActivitiesPagePathname) {
                items.push({
                    label: manausActivitiesPagePathname.translated_content.title,
                    href: manausActivitiesPagePathname.slug,
                    icon: manausActivitiesPagePathname.icon,
                    list: manausActivitiesList
                });
            } else {
                manausActivitiesList.forEach((activity) => {
                    items.push(activity);
                });
            }

            const manausInfoPagePathname = pathnamesWithInfoArray.find((pathName) => pathName && pathName.collection === 'manausamazon_info_page');
            if (manausInfoPagePathname) {
                items.push({
                    label: manausInfoPagePathname.translated_content.title,
                    href: manausInfoPagePathname.slug,
                    icon: manausInfoPagePathname.icon,
                    list: manausInfoItemsList
                });
            } else {
                manausInfoItemsList.forEach((infoItem) => {
                    items.push(infoItem);
                });
            }

            const manausAmazonPagePathname = pathnamesWithInfoArray.find((pathName) => pathName && pathName.collection === 'manausamazon_amazon_page');
            if (manausAmazonPagePathname) {
                items.push({
                    label: manausAmazonPagePathname.translated_content.title,
                    href: manausAmazonPagePathname.slug,
                    icon: manausAmazonPagePathname.icon,
                    list: manausAmazonItemsList
                });
            } else {
                manausAmazonItemsList.forEach((amazonItem) => {
                    items.push(amazonItem);
                });
            }
        } else {
            manausActivitiesList.forEach((activity) => {
                items.push(activity);
            });

            manausInfoItemsList.forEach((infoItem) => {
                items.push(infoItem);
            });

            manausAmazonItemsList.forEach((amazonItem) => {
                items.push(amazonItem);
            });
        }

        return items;
    }
}