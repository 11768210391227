'use client'

import React, { useEffect, useRef, useState } from 'react'

import { getFileUrl } from '@/lib/helpers/directus';
import { route } from '@/lib/helpers/RouteHelper';

import Link from 'next/link';
import Image from 'next/image';

import Container from '@/components/shared/Container';
import LanguageSwitcher from '@/components/shared/LanguageSwitcher';
import Typography from '@/components/shared/Typography';
import * as lucideIcons from 'lucide-react';
import { tryCatchCreateElement } from '@/lib/helpers/ComponentHelper';

export default function HeaderV3({
    params,
}) {
    const mobileMenuRef = useRef(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState(null);

    const [activeMobileSubMenu, setActiveMobileSubMenu] = useState(null);
    const [activeMobileSubSubMenu, setActiveMobileSubSubMenu] = useState(null);

    const headerRef = useRef(null);
    const subMenuRef = useRef(null);
    const subMenuInnerRef = useRef(null);
	const logoRefDesktop = useRef(null);
	const logoRefMobile = useRef(null);
	const mobileLogoRefDesktop = useRef(null);
	const mobileLogoRefMobile = useRef(null);
    const backgroundRef = useRef(null);

    useEffect(() => {
        if (!headerRef.current) return;
        
        handleScroll();
        
		prevScrollpos = window.pageYOffset;
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		}
    }, [headerRef]);
    
    useEffect(() => {
        if (subMenuOpen) {
            subMenuInnerRef.current.style.marginTop = '0rem';
            subMenuInnerRef.current.style.opacity = '1';
        } else {
            subMenuInnerRef.current.style.marginTop = '2rem';
            subMenuInnerRef.current.style.opacity = '0';
            // subMenuInnerRef.current.style.marginTop = '0rem';
            // subMenuInnerRef.current.style.opacity = '1';
        }
    }, [subMenuOpen]);

    const settings = params.settings;
    const headerNavigation = settings.navigations.header;
	if (!settings || !headerNavigation) {
		console.error('Settings or headerNavigation is missing...');
		return null;
	}

	let prevScrollpos = 0;
	
    const topClasses = [
        'h-28'
    ];
    const topClassesScrolled = [
        'h-24'
    ];

	const logoClasses = [
		'h-20'
	];
	const logoClassesScrolled = [
		'h-16'
	];

	const logoMobileClasses = [
		'w-20'
	];
	const logoMobileClassesScrolled = [
		'w-16'
	];

	const backgroundClasses = [
		'hidden',
		'opacity-0'
	];
	const backgroundClassesScrolled = [
		'block',
		'opacity-100'
    ];
    
    const subMenuClasses = [
        'top-24',
    ];
    const subMenuClassesScrolled = [
        'top-20',
    ];

	const replaceClasses = (reference, classes, classesScrolled, isScrolled) => {
		if (reference.current !== null) {
			if(classes.length > 0 && classesScrolled.length > 0) {
				classes.forEach((className) => {
					if (isScrolled) {
						reference.current.classList.add(className);
					} else {
						reference.current.classList.add(className);
					}
				});
				classesScrolled.forEach((className) => {
					if (isScrolled) {
						reference.current.classList.remove(className);
					} else {
						reference.current.classList.remove(className);
					}
				});
			}
		}
	}

	const handleScroll = () => {
		const currentScrollPos = window.pageYOffset;
        
        if (currentScrollPos <= 5) {
			replaceClasses(headerRef, topClasses, topClassesScrolled, false);
			
			replaceClasses(logoRefDesktop, logoClasses, logoClassesScrolled, false);
            replaceClasses(logoRefMobile, logoMobileClasses, logoMobileClassesScrolled, false);
			
            replaceClasses(mobileLogoRefDesktop, logoClasses, logoClassesScrolled, false);
            replaceClasses(mobileLogoRefMobile, logoMobileClasses, logoMobileClassesScrolled, false);
            

            replaceClasses(backgroundRef, backgroundClasses, backgroundClassesScrolled, false);
            
            replaceClasses(subMenuRef, subMenuClasses, subMenuClassesScrolled, false);
        } else {
            if (prevScrollpos > currentScrollPos) {
				replaceClasses(headerRef, topClasses, topClassesScrolled, false);
				
				replaceClasses(logoRefDesktop, logoClasses, logoClassesScrolled, false);
                replaceClasses(logoRefMobile, logoMobileClasses, logoMobileClassesScrolled, false);
                
                replaceClasses(mobileLogoRefDesktop, logoClasses, logoClassesScrolled, false);
                replaceClasses(mobileLogoRefMobile, logoMobileClasses, logoMobileClassesScrolled, false);

                replaceClasses(backgroundRef, backgroundClasses, backgroundClassesScrolled, false);
                
                replaceClasses(subMenuRef, subMenuClasses, subMenuClassesScrolled, false);
            } else {
				replaceClasses(headerRef, topClassesScrolled, topClasses, true);

				replaceClasses(logoRefDesktop, logoClassesScrolled, logoClasses, true);
                replaceClasses(logoRefMobile, logoMobileClassesScrolled, logoMobileClasses, true);
                
                replaceClasses(mobileLogoRefDesktop, logoClassesScrolled, logoClasses, true);
                replaceClasses(mobileLogoRefMobile, logoMobileClassesScrolled, logoMobileClasses, true);

                replaceClasses(backgroundRef, backgroundClassesScrolled, backgroundClasses, true);
                
                replaceClasses(subMenuRef, subMenuClassesScrolled, subMenuClasses, true);
            }
        }

        prevScrollpos = currentScrollPos;
    }
    
    const toggleMobileMenu = () => {
        setActiveMobileSubMenu(null);
        setActiveMobileSubSubMenu(null);
        
        const htmlDocument = document.getElementById('html-document');

        if (mobileMenuOpen) {
            htmlDocument.style.overflow = 'auto';
            
            setMobileMenuOpen(false);
        } else {
            htmlDocument.style.overflow = 'hidden';

            setMobileMenuOpen(true);
        }
    }

    const toggleSubMenu = (index) => {
        setActiveMobileSubSubMenu(null);
        
        if (activeMobileSubMenu === index) {
            setActiveMobileSubMenu(null);
        } else {
            setActiveMobileSubMenu(index);
        }
    }
    
    const toggleSubSubMenu = (index, subIndex) => {
        if (activeMobileSubSubMenu === subIndex) {
            setActiveMobileSubSubMenu(null);
        } else {
            setActiveMobileSubSubMenu(subIndex);
        }
    }

    function checkIfActiveSubMenu (index) {
        if(activeMobileSubMenu === index) {
            return true;
        }

        return false;
    }

    function checkIfActiveSubSubMenu (index, subIndex) {
        if(activeMobileSubMenu === index && activeMobileSubSubMenu === subIndex) {
            return true;
        }

        return false;
    }

    const toggleSubView = (action, functionName, e) => {
        if (action === 'enter') {
            setSubMenuOpen(true);

            setActiveSubMenu(functionName);

        } else {
            setSubMenuOpen(false);

            setActiveSubMenu(null);
        }
    }

    const animationStyle = {
        overflow: 'hidden',
        transition: 'max-height',
        transitionDuration: '0.5s',
        transitionTimingFunction: 'ease-in-out',
    }

	return (
        <>
            <div ref={mobileMenuRef} className={`block lg:hidden fixed w-full h-full z-[999] transition-all duration-300 bg-primary-800 bg-opacity-90 ${mobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                <div className='h-fit relative'>
                    <Container className='h-full flex items-center justify-between lg:justify-normal py-4 relative'>
                        {settings.logo_light && (
							<Link className='block h-full aspect-square' href={route(params, '/')}>
								<Image ref={mobileLogoRefDesktop} className="hidden lg:block h-20 w-auto transition-all duration-300" src={getFileUrl(settings.logo_light)} alt={'Logo'} width={200} height={50} />
								<Image ref={mobileLogoRefMobile} className="block lg:hidden h-auto w-20 transition-all duration-300" src={getFileUrl(settings.logo_light)} alt={'Logo'} width={200} height={50} />
							</Link>
						)}
                        <div className='h-full flex gap-x-2 items-center lg:hidden'>
                            <LanguageSwitcher params={params} languages={settings.languages} headerVersion={2} />
                            <span className='block cursor-pointer text-white' onClick={() => {toggleMobileMenu()}}>
                                {tryCatchCreateElement(lucideIcons['X'], { size: 28 })}
                            </span>
                        </div>
                    </Container>
                </div>
                <div style={{
                    height: `calc(100dvh - 7rem)`
                }} className='overflow-y-auto py-10'>
                    {(headerNavigation && headerNavigation.translated_content && headerNavigation.translated_content.topbar) && (
                        <>
                            <div className='w-full h-full overflow-y-auto no-scrollbar px-8'>
                                <div className={`flex flex-col ${settings.show_icons_in_top_menu ? 'gap-y-2' : 'gap-y-4'}`}>
                                    {headerNavigation.translated_content.topbar && (
                                        headerNavigation.translated_content.topbar.map((item, index) => {
                                            if (item.list && item.list.length > 0) {
                                                return (
                                                    <div key={index} className='flex-1 flex-col'>
                                                        {(item.label && item.url) && (
                                                            <div className="flex gap-4 py-2">
                                                                <Link href={route(params, item.url)} className='flex gap-x-2 h-fit items-center'>
                                                                    {(settings.show_icons_in_top_menu && item.icon_name) && (
                                                                        <span className='text-white'>
                                                                            {tryCatchCreateElement(lucideIcons[item.icon_name], { size: 32 })}
                                                                        </span>
                                                                    )}

                                                                    <Typography variant='p' styleVariant='h4' className='transition-all duration-300 font-bold text-secondary-100 hover:text-tertiary-500'>
                                                                        {item.label}
                                                                    </Typography>
                                                                </Link>
                                                                <div className='w-auto h-full' onClick={() => { toggleSubMenu(index) }}>
                                                                    <span className='cursor-pointer text-secondary-100 h-full w-full flex justify-center items-center'>
                                                                        {tryCatchCreateElement(lucideIcons['ChevronsRight'], {
                                                                            size: 28, style: {
                                                                                transform: `rotate(${checkIfActiveSubMenu(index) ? '90deg' : '0deg'})`,
                                                                                transition: 'all',
                                                                                transitionDuration: '0.1s',
                                                                                transitionTimingFunction: 'ease-in-out',
                                                                            }
                                                                        })}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {item.list.map((subItem, subIndex) => {
                                                            return (
                                                                <div key={subIndex} style={{
                                                                    ...animationStyle,
                                                                    maxHeight: checkIfActiveSubMenu(index) ? '900px' : '0px',
                                                                }}>
                                                                    {(subItem.label && subItem.href) && (
                                                                        <div className="flex gap-4 ml-2 py-2">
                                                                            <Link href={route(params, subItem.href)} className='flex gap-x-2 h-fit items-center'>
                                                                                {(settings.show_icons_in_top_menu && subItem.icon_name) && (
                                                                                    <span className='text-white'>
                                                                                        {tryCatchCreateElement(lucideIcons[subItem.icon_name], { size: 32 })}
                                                                                    </span>
                                                                                )}

                                                                                <Typography variant='p' styleVariant='h5' className='transition-all duration-300 font-bold text-secondary-100 hover:text-tertiary-500'>
                                                                                    {subItem.label}
                                                                                </Typography>
                                                                            </Link>
                                                                            {(subItem.list && subItem.list.length > 0) && (
                                                                                <div className='w-fit h-fit' onClick={() => {toggleSubSubMenu(index, subIndex)}} >
                                                                                    <span className='cursor-pointer text-secondary-100 h-full w-full flex justify-center items-center'>
                                                                                        {tryCatchCreateElement(lucideIcons['ChevronsRight'], {
                                                                                            size: 24, style: {
                                                                                                transform: `rotate(${checkIfActiveSubSubMenu(index, subIndex) ? '90deg' : '0deg'})`,
                                                                                                transition: 'all',
                                                                                                transitionDuration: '0.1s',
                                                                                                transitionTimingFunction: 'ease-in-out',
                                                                                            }
                                                                                        })}
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}

                                                                    {subItem.list.map((subSubItem, subSubIndex) => {
                                                                        return (
                                                                            <div key={subSubIndex} style={{
                                                                                ...animationStyle,
                                                                                maxHeight: checkIfActiveSubSubMenu(index, subIndex) ? '900px' : '0px',
                                                                            }} className='ml-4'>
                                                                                {(subSubItem.label && subSubItem.href) && (
                                                                                    <div className="flex gap-4 ml-2 py-2">
                                                                                        <Link href={route(params, subSubItem.href)} className='flex gap-x-2 h-fit items-center'>
                                                                                            {(settings.show_icons_in_top_menu && subSubItem.icon_name) && (
                                                                                                <span className='text-white'>
                                                                                                    {tryCatchCreateElement(lucideIcons[subSubItem.icon_name], { size: 32 })}
                                                                                                </span>
                                                                                            )}

                                                                                            <Typography variant='p' styleVariant='h6' className='transition-all duration-300 font-bold text-secondary-100 hover:text-tertiary-500'>
                                                                                                {subSubItem.label}
                                                                                            </Typography>
                                                                                        </Link>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div key={index} className='flex-1 flex-col py-2'>
                                                        {(item.label && item.url) && (
                                                            <Link href={route(params, item.url)} className='flex gap-x-2 h-fit items-center'>
                                                                {(settings.show_icons_in_top_menu && item.icon_name) && (
                                                                    <span className='text-white'>
                                                                        {tryCatchCreateElement(lucideIcons[item.icon_name], { size: 32 })}
                                                                    </span>
                                                                )}

                                                                <Typography variant='p' styleVariant='h4' className='transition-all duration-300 font-bold text-secondary-100 hover:text-tertiary-500'>
                                                                    {item.label}
                                                                </Typography>
                                                            </Link>
                                                        )}
                                                    </div>
                                                )
                                            }
                                        })
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

			<header ref={headerRef} className='fixed w-full z-[998] transition-all duration-300 text-shadow-sm shadow-black'>
				<div className='h-full relative'>
					<div ref={backgroundRef} className='absolute inset-0 hidden opacity-0 transition-opacity duration-300'>
						<Image src='/images/background-image.jpeg' alt='Tree leaves in the background' width={1920} height={1080} className='h-full object-cover object-top opacity-55' />
						<div className='absolute inset-0 bg-primary-800 bg-opacity-90'></div>
                    </div>

					<Container className='h-full flex items-center justify-between lg:justify-normal relative'>
						{settings.logo_light && (
							<Link className='flex h-full aspect-square items-center' href={route(params, '/')}>
								<Image ref={logoRefDesktop} className="hidden lg:block h-20 w-auto transition-all duration-300" src={getFileUrl(settings.logo_light)} alt={'Logo'} width={200} height={50} />
								<Image ref={logoRefMobile} className="block lg:hidden h-auto w-20 transition-all duration-300" src={getFileUrl(settings.logo_light)} alt={'Logo'} width={200} height={50} />
							</Link>
						)}
						
						{(headerNavigation && headerNavigation.translated_content && headerNavigation.translated_content.topbar) ? (
                            <>
                                <div className='h-full w-full hidden lg:flex lg:gap-x-8 justify-between items-center pl-16 pr-8 py-4'>
                                    {headerNavigation.translated_content.topbar && (
                                        headerNavigation.translated_content.topbar.map((item, index) => (
                                            <Link onMouseEnter={(e) => {if(item.list) toggleSubView('enter', item.function_name, e)}} onMouseLeave={(e) => {if(item.list) toggleSubView('leave', item.function_name, e)}} href={route(params, item.url)} key={index} className='h-full flex items-center group px-4'>
                                                {(item.label && item.url) && (
                                                    <div className='flex h-fit items-center'>
                                                        <Typography variant='p' className='transition-all duration-300 font-bold group-hover::text-tertiary-500'>
                                                            {item.label}
                                                        </Typography>
            
                                                        {(settings.show_icons_in_top_menu && item.icon_name) && (
                                                            <span className='ml-2 text-white'>
                                                                {tryCatchCreateElement(lucideIcons[item.icon_name], { size: 18 })}
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </Link>
                                        ))
                                    )}
                                    <LanguageSwitcher params={params} languages={settings.languages} headerVersion={2} />
                                </div>
                                    
                                <div className='h-full flex items-center lg:hidden'>
                                    <span className={`${mobileMenuOpen ? 'hidden': 'block'} cursor-pointer text-white`} onClick={() => {toggleMobileMenu()}}>
                                        {tryCatchCreateElement(lucideIcons['AlignJustify'], { size: 28 })}
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='h-full w-full hidden lg:flex lg:gap-x-8 justify-end items-center pl-16 pr-8 py-4'>
                                    <LanguageSwitcher params={params} languages={settings.languages} headerVersion={2} />
                                </div>
                                    
                                <div className='h-full flex items-center lg:hidden'>
                                    <span className={`${mobileMenuOpen ? 'hidden': 'block'} cursor-pointer text-white`} onClick={() => {toggleMobileMenu()}}>
                                        {tryCatchCreateElement(lucideIcons['AlignJustify'], { size: 28 })}
                                    </span>
                                </div>
                            </>
						)}
					</Container>
				</div>
            </header>

            <div ref={subMenuRef} className={'hidden lg:flex justify-center items-center fixed w-container mx-auto z-[998] transition-all duration-500'}>
                <div ref={subMenuInnerRef} onMouseEnter={(e) => toggleSubView('enter', activeSubMenu, e)} onMouseLeave={(e) => toggleSubView('leave', null, e)} className={`w-container mx-auto p-10 transition-all duration-300 bg-black bg-opacity-80 rounded-md ${subMenuOpen ? 'flex' : 'hidden'}`}>
                {/* <div ref={subMenuInnerRef} onMouseEnter={(e) => toggleSubView('enter', activeSubMenu, e)} onMouseLeave={(e) => toggleSubView('leave', null, e)} className={`w-container mx-auto p-10 transition-all duration-300 bg-black rounded-md ${subMenuOpen ? 'flex' : 'flex'}`}> */}
                    {(headerNavigation && headerNavigation.translated_content && headerNavigation.translated_content.topbar) && (
                        <div className='flex-1 transition-all duration-300'>
                            {headerNavigation.translated_content.topbar && (
                                headerNavigation.translated_content.topbar.map((item, index) => {                                    
                                    if (item.list && item.list.length > 0) {
                                        return (
                                            <div key={index} className={`${activeSubMenu && activeSubMenu === item.function_name ? 'opacity-100 grid' : 'opacity-0 hidden'} grid-cols-${item.list.length}`}>
                                            {/* <div key={index} className={`${'generateToursSubMenu' === item.function_name ? 'opacity-100 grid' : 'opacity-0 hidden'} divide-x divide-gray-700 divide-opacity-50 grid-cols-${item.list.length}`}> */}
                                                {item.list.map((subItem, subIndex) => {
                                                    const leftPadding = subIndex === 0 ? 'pl-0' : 'pl-8';
                                                    const rightPadding = (subIndex === item.list.length - 1) ? 'pr-0' : 'pr-8';
                                                    return (
                                                        <div key={subIndex} className={`text-white col-span-1 h-full ${leftPadding} ${rightPadding}`}>
                                                            <Link href={route(params, subItem.href ?? '')}>
                                                                <Typography variant='p' styleVariant='h5' className={`text-secondary-100 ${subItem.list && subItem.list.length > 0 ? 'pb-4 border-b border-b-gray-700' : ''}`}>
                                                                    {subItem.label}
                                                                </Typography>
                                                            </Link>
                                                            <div className='grid grid-cols-1 divide-y divide-gray-700 divide-opacity-50'>
                                                                {(subItem.list && subItem.list.length > 0) && (
                                                                    <>
                                                                        {subItem.list.map((subSubItem, subSubIndex) => {
                                                                            return (
                                                                                <div key={subSubIndex} className=' py-3 '>
                                                                                    <Link href={route(params, subSubItem.href ?? '')}>
                                                                                        <Typography variant='p' className={`font-bold transition-colors duration-100 text-white hover:text-secondary-100`}>
                                                                                            {subSubItem.label}
                                                                                        </Typography>
                                                                                    </Link>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    } else {

                                    }
                                })
                            )}
                        </div>
                    )}
                </div>
            </div>
		</>
	)
}
