import React from 'react';

import cx from "classnames";
import { tryCatchCreateElement } from '@/lib/helpers/ComponentHelper';

const Typography = ({ variant, styleVariant, hasShadow = false, className, children }) => {
    if (
        !variant || 
        (
            variant !== 'h1' &&
            variant !== 'h2' &&
            variant !== 'h3' &&
            variant !== 'h4' &&
            variant !== 'h5' &&
            variant !== 'h6' &&
            variant !== 'p'
        )
    ) {
        console.error('Provide a valid variant');
        return null;
    }
    
    if (
        !styleVariant || 
        (
            styleVariant !== 'h1' &&
            styleVariant !== 'h2' &&
            styleVariant !== 'h3' &&
            styleVariant !== 'h4' &&
            styleVariant !== 'h5' &&
            styleVariant !== 'h6' &&
            styleVariant !== 'p'
        )
    ) {
        styleVariant = variant;
    }

    if (Array.isArray(children)) {
        children = children.join('');
    } else if (typeof children !== 'string') {
        children = '';
    }

    return (
        tryCatchCreateElement(
            variant,
            {
                className: cx(`${styleVariant} ${hasShadow ? 'text-shadow-sm shadow-black' : ''}`, className),
                dangerouslySetInnerHTML: {
                    __html: children.replaceAll('\n', '<br />')
                }
            }
        )
    );
}

export default Typography