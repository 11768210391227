import { Instance, getLanguageItemByLocale } from "@/lib/helpers/directus";
import { readItems } from '@directus/sdk'

export const getManausActivities = async (locale) => {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                languages_code: { _eq: language.code },
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
        };

        const filterObject = {
            status: {
                _eq: 'published',
            },
        };

        const fields = [
            '*',
            {
                header_media: [
                    'id',
                    'folder',
                    'type',
                    'title',
                    'filename_disk',
                    'filename_download',
                    'width',
                    'height',
                    'duration',
                ],
                translations: [
                    '*',
                ],
            },
        ];

        const activities = await Instance.request(readItems('manausamazon_activities', {
            sort: ['sort', 'date_created'],
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!activities) {
            console.log('getManausActivities activities is null', activities);
            return null;
        } else {
            activities.map((activity) => {
                if (activity) {
                    activity.translated_content = activity.translations[0];

                    delete activity.translations;
                }

                return activity;
            });
            
            return activities
        }
    }
}

export const getManausInfoItems = async (locale) => {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
        };

        const filterObject = {
            status: {
                _eq: 'published',
            },
        };

        const fields = [
            '*',
            {
                header_media: [
                    'id',
                    'folder',
                    'type',
                    'title',
                    'filename_disk',
                    'filename_download',
                    'width',
                    'height',
                    'duration',
                ],
                translations: [
                    '*',
                ],
            },
        ];

        const infoItems = await Instance.request(readItems('manausamazon_info_items', {
            sort: ['sort', 'date_created'],
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!infoItems) {
            return null;
        } else {
            infoItems.map((infoItem) => {
                if (infoItem) {
                    infoItem.translated_content = infoItem.translations[0];

                    delete infoItem.translations;
                }

                return infoItem;
            });

            return infoItems
        }
    }
}

export const getManausAmazonItems = async (locale) => {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
        };

        const filterObject = {
            status: {
                _eq: 'published',
            },
        };

        const fields = [
            '*',
            {
                header_media: [
                    'id',
                    'folder',
                    'type',
                    'title',
                    'filename_disk',
                    'filename_download',
                    'width',
                    'height',
                    'duration',
                ],
                translations: [
                    '*',
                ],
            },
        ];

        const amazonItems = await Instance.request(readItems('manausamazon_amazon_items', {
            sort: ['sort', 'date_created'],
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!amazonItems) {
            console.log('agetManausmazonItems amazonItems', amazonItems);
            return null;
        } else {
            amazonItems.map((amazonItem) => {
                if (amazonItem) {
                    amazonItem.translated_content = amazonItem.translations[0];

                    delete amazonItem.translations;
                }

                return amazonItem;
            });

            return amazonItems
        }
    }
}

export const getManausAmazonPathnames = async () => {
    const filterObject = {
        status: {
            _eq: 'published',
        }
    };

    const fields = [
        '*',
        {
            translations: [
                '*',
                'languages_code.slug_code',
            ],
        }
    ];

    const info = await Instance.request(readItems('manausamazon_info_items', {
        sort: ['sort', 'date_created'],
        // deep: deepFilterObject,
        filter: filterObject,
        fields: fields
    }));
    
    const amazon = await Instance.request(readItems('manausamazon_amazon_items', {
        sort: ['sort', 'date_created'],
        // deep: deepFilterObject,
        filter: filterObject,
        fields: fields
    }));
    
    const activities = await Instance.request(readItems('manausamazon_activities', {
        sort: ['sort', 'date_created'],
        // deep: deepFilterObject,
        filter: filterObject,
        fields: fields
    }));

    if (!info) console.log('getManausAmazonPathnames info is null', info);
    if (!amazon) console.log('getManausAmazonPathnames amazon is null', amazon);
    if (!activities) console.log('getManausAmazonPathnames activities is null', activities);

    if(!info && !amazon && !activities) {
        return null;
    }

    return [
        ...info.map((item) => {
            return {
                ...item,
                collection: 'manausamazon_info_items'
            }
        }),
        ...amazon.map((item) => {
            return {
                ...item,
                collection: 'manausamazon_amazon_items'
            }
        }),
        ...activities.map((item) => {
            return {
                ...item,
                collection: 'manausamazon_activities'
            }
        })
    ];
}

export const getManauseInfoData = async (locale, info_id) => {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
            content_blocks: {
                status: {
                    _eq: 'published',
                },
                translations: deepLangFilterObj,
            },
            seo: {
                status: {
                    _eq: 'published',
                },
                translations: deepLangFilterObj,
            },
        };

        const filterObject = {
            status: {
                _eq: 'published',
            },
            id: {
                _eq: info_id,
            }
        };

        const fields = [
            '*',
            {
                seo: [
                    '*',
                    {
                        translations: [
                            '*',
                        ]
                    }
                ],
                header_media: [
                    'id',
                    'folder',
                    'type',
                    'title',
                    'filename_disk',
                    'filename_download',
                    'width',
                    'height',
                    'duration',
                ],
                translations: [
                    '*',
                ],
                content_blocks: [
                    '*',
                    {
                        media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                        translations: [
                            '*',
                        ],
                    },
                ],
            },
        ];

        const info = await Instance.request(readItems('manausamazon_info_items', {
            sort: ['sort', 'date_created'],
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!info) {
            return null;
        } else {
            const infoItem = info[0];

            if (infoItem) {
                infoItem.translated_content = infoItem.translations[0];
                delete infoItem.translations;

                if (infoItem.seo) {
                    infoItem.seo.translated_content = infoItem.seo.translations[0];
                    delete infoItem.seo.translations;
                }


                if (infoItem.content_blocks) {
                    infoItem.content_blocks.map((content_block) => {
                        content_block.translated_content = content_block.translations[0];

                        delete content_block.translations;

                        return content_block;
                    });
                }

                return infoItem;
            } else {
                return null;
            }
        }
    }
}

export const getManausAmazonData = async (locale, amazon_item_id) => {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
            content_blocks: {
                status: {
                    _eq: 'published',
                },
                translations: deepLangFilterObj,
                seo: {
                status: {
                    _eq: 'published',
                },
                translations: deepLangFilterObj,
            },
            },
        };

        const filterObject = {
            status: {
                _eq: 'published',
            },
            id: {
                _eq: amazon_item_id,
            }
        };

        const fields = [
            '*',
            {
                seo: [
                    '*',
                    {
                        translations: [
                            '*',
                        ]
                    }
                ],
                header_media: [
                    'id',
                    'folder',
                    'type',
                    'title',
                    'filename_disk',
                    'filename_download',
                    'width',
                    'height',
                    'duration',
                ],
                translations: [
                    '*',
                ],
                content_blocks: [
                    '*',
                    {
                        media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                        translations: [
                            '*',
                        ],
                    },
                ],
            },
        ];

        const amazonItems = await Instance.request(readItems('manausamazon_amazon_items', {
            sort: ['sort', 'date_created'],
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!amazonItems) {
            return null;
        } else {
            const amazonItem = amazonItems[0];

            if (amazonItem) {
                amazonItem.translated_content = amazonItem.translations[0];
                delete amazonItem.translations;

                if (amazonItem.seo) {
                    amazonItem.seo.translated_content = amazonItem.seo.translations[0];
                    delete amazonItem.seo.translations;
                }

                if (amazonItem.content_blocks) {
                    amazonItem.content_blocks.map((content_block) => {
                        content_block.translated_content = content_block.translations[0];

                        delete content_block.translations;

                        return content_block;
                    });
                }

                return amazonItem;
            } else {
                return null;
            }
        }
    }
}

export const getManausActivityData = async (locale, activity_id) => {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
            content_blocks: {
                status: {
                    _eq: 'published',
                },
                translations: deepLangFilterObj,
                seo: {
                status: {
                    _eq: 'published',
                },
                translations: deepLangFilterObj,
            },
            },
        };

        const filterObject = {
            status: {
                _eq: 'published',
            },
            id: {
                _eq: activity_id,
            }
        };

        const fields = [
            '*',
            {
                seo: [
                    '*',
                    {
                        translations: [
                            '*',
                        ]
                    }
                ],
                header_media: [
                    'id',
                    'folder',
                    'type',
                    'title',
                    'filename_disk',
                    'filename_download',
                    'width',
                    'height',
                    'duration',
                ],
                translations: [
                    '*',
                ],
                content_blocks: [
                    '*',
                    {
                        media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                        translations: [
                            '*',
                        ],
                    },
                ],
            },
        ];

        const activities = await Instance.request(readItems('manausamazon_activities', {
            sort: ['sort', 'date_created'],
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!activities) {
            return null;
        } else {
            const activity = activities[0];

            if (activity) {
                activity.translated_content = activity.translations[0];
                delete activity.translations;

                if (activity.seo) {
                    activity.seo.translated_content = activity.seo.translations[0];
                    delete activity.seo.translations;
                }

                if (activity.content_blocks) {
                    activity.content_blocks.map((content_block) => {
                        content_block.translated_content = content_block.translations[0];
                        delete content_block.translations;

                        return content_block;
                    });
                }

                return activity;
            } else {
                return null;
            }
        }
    }
}
