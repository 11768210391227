import { forwardRef } from 'react';

const ButtonArrowLeft = forwardRef(({ fill = 'white', stroke = 'white', className = '' }, ref) => {
    return (
        <svg ref={ref} className={`${className}`} width="18" height="30" viewBox="0 0 14 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.345 28.7129L9.6692 28.7129L1.17294 14.8565L9.6692 1L18.345 1" fill={`var(--color-${fill})`} />
            <path d="M18.345 28.7129L9.6692 28.7129L1.17294 14.8565L9.6692 1L18.345 1" stroke={`var(--color-${stroke})`} strokeWidth="2"/>
        </svg>
    );
});
ButtonArrowLeft.displayName = 'ButtonArrowLeft';

export default ButtonArrowLeft
