import { Instance, getLanguageItemByLocale } from "@/lib/helpers/directus";
import { readItems } from '@directus/sdk'

export const getTourCategories = async (locale, withChildren = false) => {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
        };
        if (withChildren) {
            deepFilterObject.tours = {
                translations: deepLangFilterObj,
                status: {
                    _eq: 'published',
                }
            };
        }

        const filterObject = {
            status: {
                _eq: 'published',
            }
        };

        const fields = [
            '*',
            {
                translations: [
                    '*'
                ],
                header_media: [
                    'id',
                    'folder',
                    'type',
                    'title',
                    'filename_disk',
                    'filename_download',
                    'width',
                    'height',
                    'duration',
                ],
            }
        ];
        if (withChildren) {
            fields.push({
                tours: [
                    '*',
                    {
                        translations: [
                            '*'
                        ]
                    }
                ]
            });
        }


        const tours_categories = await Instance.request(readItems('tours_categories', {
            sort: ['sort', 'date_created'],
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!tours_categories) {
            return null;
        } else {
            return tours_categories.map((tours_category) => {
                if (tours_category) {
                    tours_category.translated_content = tours_category.translations[0];
                    delete tours_category.translations;
                }

                if (withChildren) {
                    tours_category.tours = tours_category.tours.map((tour) => {
                        tour.translated_content = tour.translations[0];
                        delete tour.translations;

                        return tour;
                    });
                }

                return tours_category;
            });
        }
    }
}

export const getTourCategoriesPathnames = async () => {

    const deepFilterObject = {
        tours: {
            status: {
                _eq: 'published',
            }
        },
    };

    const filterObject = {
        status: {
            _eq: 'published',
        }
    };

    const fields = [
        '*',
        {
            translations: [
                '*',
                'languages_code.slug_code',
            ],
            tours: [
                '*',
                {
                    translations: [
                        '*',
                        'languages_code.slug_code',
                    ]
                }
            ],
        }
    ];

    const tours_categories = await Instance.request(readItems('tours_categories', {
        sort: ['sort', 'date_created'],
        deep: deepFilterObject,
        filter: filterObject,
        fields: fields
    }));

    if (!tours_categories) {
        return null;
    } else {
        // console.log('getTourCategoriesPathnames tours_categories', tours_categories);
        return tours_categories
    }
}

export const getTourCategoryData = async (locale, tours_categories_id) => {
    const withChildren = true;

    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
            information_blocks: {
                translations: deepLangFilterObj,
                status: {
                    _eq: 'published',
                }
            },
            seo: {
                status: {
                    _eq: 'published',
                },
                translations: deepLangFilterObj,
            },
        };
        if (withChildren) {
            deepFilterObject.tours = {
                translations: deepLangFilterObj,
                status: {
                    _eq: 'published',
                }
            };
        }

        const filterObject = {
            status: {
                _eq: 'published',
            },
            id: {
                _eq: tours_categories_id,
            }
        };

        const fields = [
            '*',
            {
                seo: [
                    '*',
                    {
                        translations: [
                            '*',
                        ]
                    }
                ],
            },
            {
                header_media: [
                    'id',
                    'folder',
                    'type',
                    'title',
                    'filename_disk',
                    'filename_download',
                    'width',
                    'height',
                    'duration',
                ],
                translations: [
                    '*'
                ],
                information_blocks: [
                    '*',
                    {
                        media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                        translations: [
                            '*'
                        ]
                    }
                ],
            },
        ];
        if (withChildren) {
            fields.push({
                tours: [
                    '*',
                    {
                        header_media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                        translations: [
                            '*'
                        ]
                    }
                ]
            });
        }

        const tours_categories = await Instance.request(readItems('tours_categories', {
            sort: ['sort', 'date_created'],
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!tours_categories) {
            return null;
        } else {
            const tours_category = tours_categories[0];

            if (tours_category) {
                if (tours_category.translations) {
                    tours_category.translated_content = tours_category.translations[0];
                    delete tours_category.translations;
                }

                if (tours_category.seo) {
                    tours_category.seo.translated_content = tours_category.seo.translations[0];
                    delete tours_category.seo.translations;
                }

                if (tours_category.information_blocks) {
                    tours_category.information_blocks = tours_category.information_blocks.map((information_block) => {
                        if (information_block.translations) {
                            information_block.translated_content = information_block.translations[0];
                            delete information_block.translations;
                        }

                        return information_block;
                    });
                }

                if (tours_category.tours) {
                    tours_category.tours = tours_category.tours.map((tour) => {
                        if (tour.translations) {
                            tour.translated_content = tour.translations[0];
                            delete tour.translations;
                        }

                        return tour;
                    });
                }

                return tours_category;
            } else {
                return null;
            }
        }
    }
}

export const getToursData = async (locale) => {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
            seo: {
                status: {
                    _eq: 'published',
                },
                translations: deepLangFilterObj,
            },
        };

        const filterObject = {
            status: {
                _eq: 'published',
            }
        };

        const fields = [
            '*',
            {
                seo: [
                    '*',
                    {
                        translations: [
                            '*',
                        ]
                    }
                ],
            },
            {
                header_media: [
                    'id',
                    'folder',
                    'type',
                    'title',
                    'filename_disk',
                    'filename_download',
                    'width',
                    'height',
                    'duration',
                ],
                translations: [
                    '*',
                    {
                        image_block_media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                    }
                ],
            },
        ];

        const tours = await Instance.request(readItems('tours', {
            sort: ['sort', 'date_created'],
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!tours) {
            return null;
        } else {
            return tours.map((tour) => {
                if (tour) {
                    if (tour.translations) {
                        tour.translated_content = tour.translations[0];
                        delete tour.translations;
                    }

                    if (tour.seo) {
                        tour.seo.translated_content = tour.seo.translations[0];
                        delete tour.seo.translations;
                    }

                    if(tour.tour_parts) {
                        tour.tour_parts.map((tour_part) => {
                            if (tour_part.translations) {
                                tour_part.translated_content = tour_part.translations[0];
                                delete tour_part.translations;
                            }
                        });
                    }
    
                    return tour;
                } else {
                    return null;
                }
            });
        }
    }
}

export const getTourData = async (locale, tour_id) => {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: { _eq: language.code },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
            seo: {
                status: {
                    _eq: 'published',
                },
                translations: deepLangFilterObj,
            },
            tour_parts: {
                status: {
                    _eq: 'published',
                },
                translations: deepLangFilterObj,
            },
        };

        const filterObject = {
            status: {
                _eq: 'published',
            },
            id: {
                _eq: tour_id,
            }
        };

        const fields = [
            '*',
            {
                seo: [
                    '*',
                    {
                        translations: [
                            '*',
                        ]
                    }
                ],
            },
            {
                header_media: [
                    'id',
                    'folder',
                    'type',
                    'title',
                    'filename_disk',
                    'filename_download',
                    'width',
                    'height',
                    'duration',
                ],
                translations: [
                    '*',
                    {
                        image_block_media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                    }
                ],
                tour_parts: [
                    '*',
                    {
                        media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                    },
                    {
                        translations: [
                            '*',
                            
                        ]
                    },
                ]
            },
        ];

        const tours = await Instance.request(readItems('tours', {
            sort: ['sort', 'date_created'],
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!tours) {
            return null;
        } else {
            const tour = tours[0];

            if (tour) {
                if (tour.translations) {
                    tour.translated_content = tour.translations[0];
                    delete tour.translations;
                }

                if (tour.seo) {
                    tour.seo.translated_content = tour.seo.translations[0];
                    delete tour.seo.translations;
                }

                if(tour.tour_parts) {
                    tour.tour_parts.map((tour_part) => {
                        if (tour_part.translations) {
                            tour_part.translated_content = tour_part.translations[0];
                            delete tour_part.translations;
                        }
                    });
                }

                return tour;
            } else {
                return null;
            }
        }
    }
}

export const getRelatedTours = async (locale, tours_categories_id, tour_id) => {
    const language = await getLanguageItemByLocale(locale);
    if (!language) {
        return null;
    } else {
        const deepLangFilterObj = {
            _filter: {
                _and: [
                    {
                        languages_code: {
                            _eq: language.code
                        },
                    },
                ],
            },
        };

        const deepFilterObject = {
            translations: deepLangFilterObj,
            _filter: {
                tours_categories: {
                    id: {
                        _eq: tours_categories_id
                    },
                    translations: deepLangFilterObj,
                },
            }
        };

        const filterObject = {
            status: {
                _eq: 'published',
            },
            id: {
                _neq: tour_id,
            }
        };

        const fields = [
            '*',
            {
                translations: [
                    '*'
                ]
            },
        ];

        const related_tours = await Instance.request(readItems('tours', {
            sort: ['sort', 'date_created'],
            deep: deepFilterObject,
            filter: filterObject,
            fields: fields
        }));

        if (!related_tours) {
            return null;
        } else {
            return related_tours.map((related_tour) => {
                if (related_tour) {
                    related_tour.translated_content = related_tour.translations[0];
                    delete related_tour.translations;
                }

                return related_tour;
            });

        }
    }
}